/* Estiliza a barra de rolagem para navegadores baseados em WebKit (Chrome, Edge, Safari) */
.scrollbar-thin::-webkit-scrollbar {
    width: 8px; /* Largura da barra vertical */
    height: 8px; /* Altura da barra horizontal */
}

.scrollbar-thin::-webkit-scrollbar-thumb {
    background-color: #a0aec0; /* Cor do "thumb" da barra */
    border-radius: 4px; /* Borda arredondada */
}

.scrollbar-thin::-webkit-scrollbar-track {
    background-color: #edf2f7; /* Cor da trilha da barra */
}

/* Para navegadores que suportam scrollbar-width */
.scrollbar-thin {
    scrollbar-width: thin; /* Torna a barra mais fina no Firefox */
}
